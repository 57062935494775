@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "assets/style/_variables.scss";

* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  transition: all 0.3s;
}

html {
  font-family: "Poppins", sans-serif;
  color: $black;
}

#root {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100dvh;
  width: 100vw;
  scroll-behavior: smooth;
}
