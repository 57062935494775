@keyframes blob-animation {
  0% {
    transform: rotate(0) translate(0);
  }
  15% {
    transform: rotate(135deg) translate(-12rem);
  }
  85% {
    transform: rotate(-45deg) translate(2rem);
  }
  100% {
    transform: rotate(0) translate(0);
  }
}

@keyframes scroll {
  0% {
    top: 9px;
  }
  25% {
    top: 23px;
  }
  50% {
    top: 9px;
  }
  100% {
    top: 9px;
  }
}

@keyframes fade-in-text {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  20% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  21% {
    transform: translateY(1rem);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
