.advantage-card {
  position: relative;
  width: 31rem;
  background-color: #ffffff73;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 25px;
  backdrop-filter: blur(10px);

  &::before {
    content: "";
    position: absolute;
    inset: 0px;
    border-radius: 25px;
    pointer-events: none;
    mask-composite: exclude;
    background: linear-gradient(
      rgb(255, 255, 255) 0%,
      rgba(255, 255, 255, 0.5) 100%
    );
    border: 1px solid transparent;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#ffffff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#ffffff 0 0);
    -webkit-mask-composite: destination-out;
  }

  @media screen and (max-width: $lg) {
    width: 100%;
  }
}
