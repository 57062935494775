.feature-card {
  position: relative;
  width: 560px;
  height: 198px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 30px;
  border: none;
  border-radius: 1rem;
  background: #fafafa59;
  filter: grayscale(1);
  color: $grey-50;

  .title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
  }

  &:not(.description) {
    height: auto;
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0px;
    pointer-events: none;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: -1;
  }

  @media screen and (max-width: $lg) {
    width: 100%;
  }
}
.feature-card.active {
  color: $black;
  filter: grayscale(0);

  &:first-child,
  &:last-child {
    background: radial-gradient(
      circle 560px at top right,
      #ff800026 0%,
      #ff800000 75%
    );
  }
  &:nth-child(2) {
    background: radial-gradient(
      circle 560px at top right,
      #e5104526 0%,
      #e5104500 75%
    );
  }
  &:nth-child(3) {
    background: radial-gradient(
      circle 560px at top right,
      #39888026 0%,
      #39888000 75%
    );
  }
  &:nth-child(4) {
    background: radial-gradient(
      circle 560px at top right,
      #5090ff26 0%,
      #5090ff00 75%
    );
  }
  &:nth-child(5) {
    background: radial-gradient(
      circle 560px at top right,
      #723f0d26 0%,
      #723f0d00 75%
    );
  }
}
