footer {
  width: 100%;
  padding: 3.5rem 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  .footer-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    nav {
      display: flex;
      align-items: center;
      gap: 2.5rem;
    }
  }

  .footer-legal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2.5rem;

    .legal {
      display: flex;
      align-items: center;
      gap: 2.5rem;
    }
  }

  @media screen and (max-width: $xl) {
    padding-left: $padding-xl;
    padding-right: $padding-xl;
  }

  @media screen and (max-width: $lg) {
    padding: 2rem $padding-lg;

    .footer-nav {
      flex-direction: column;
      align-items: flex-end;
      gap: 2rem;
      padding-bottom: 2rem;
      border-bottom: 2px solid $grey-10;

      .footer-logo {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
      nav {
        width: 100%;
        flex-direction: column;
        align-items: flex-end;
        gap: 1.25rem;
      }
    }
    .footer-legal {
      flex-direction: column-reverse;
      align-items: flex-end;
      gap: 2rem;
      padding-top: 2rem;
    }
  }
}
