main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: $padding-xxl $padding-xxl 0;
  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .hero-text {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
  }

  .video {
    position: relative;
    padding: 1rem;
    border-radius: 1rem;
    background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.5)
    );

    &::before {
      content: "";
      position: absolute;
      border-radius: 1rem;
      inset: 0px;
      pointer-events: none;
      background: linear-gradient(
        90deg,
        rgb(255, 255, 255, 0.15),
        rgb(255, 255, 255, 0.05)
      );
      border: 1px solid transparent;
      mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#ffffff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#ffffff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
    }

    video {
      border: 1px solid $grey-5;
      border-radius: 0.5rem;
      width: 100%;
      height: auto;
    }
  }
}

.property-insights {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: 12rem 9.375rem 0;
  .property-insights-text {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    p {
      max-width: 670px;
    }
  }

  .scroll-sign {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .scroll-animated {
      position: relative;
      height: 48px;
      width: 28px;
      border-radius: 16px;
      background: $gradient;
      display: flex;
      padding: 4px;

      &::before {
        content: "";
        position: absolute;
        top: 9px;
        left: 50%;
        right: 0;
        bottom: 0;
        transform: translateX(-50%);
        background: $gradient;
        width: 12px;
        height: 16px;
        border-radius: 32px;
        animation: scroll 4s cubic-bezier(0.175, 0.885, 0.32, 1) infinite;
      }

      &::after {
        content: "";
        background-color: $white;
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }
    }
    &::after {
      content: "Scroll down to see more.";
      background: $gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: fade-in-text 4s linear infinite;
    }
    @media screen and (max-width: $lg) {
      &::after {
        content: "swipe down to see more.";
      }
    }
  }

  .insights {
    position: relative;
    width: 100%;
    height: 4500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2.5rem;
    .cards-wrapper {
      width: 100%;
      height: 100dvh;
      position: sticky;
      top: 10rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 30px;
      padding-top: 1rem;
    }

    .insight-images {
      position: sticky;
      width: 100%;
      top: 10rem;
      height: 100dvh;
      overflow: hidden;
      perspective: 1000px;
      padding-top: 1rem;
      display: flex;
      justify-content: center;
      img {
        transition-property: top, left, right, bottom;
        transition-duration: 0.5s;
        position: absolute;
        width: 90%;
      }
    }
  }

  .mobile-insights {
    height: 4000px;
    display: none;

    .cards-wrapper {
      position: sticky;
      top: 7rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    .feature-card-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      overflow-y: hidden;

      .feature-image {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: $lg) {
    .insights {
      display: none;
    }

    .mobile-insights {
      display: block;
    }
  }
}

.advantages {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: 12rem 9.375rem 0;
  .advantages-text {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    p {
      max-width: 670px;
    }
  }

  .advantages-cards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2.5rem;
    .cards-row {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 2.5rem;

      * {
        flex: 1 1;
      }

      @media screen and (max-width: $lg) {
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

section:has(> .security) {
  position: relative;
  padding: 12rem 9.375rem 4rem;
  display: flex;
  justify-content: center;

  .security {
    position: relative;
    padding: 4rem;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.35);
    width: max-content;

    &::before {
      content: "";
      position: absolute;
      border-radius: 1rem;
      inset: 0px;
      pointer-events: none;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.15),
        rgba(255, 255, 255, 0.05)
      );
      border: 1px solid transparent;
      mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#ffffff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#ffffff 0 0);
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
    }

    .security-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2.5rem;

      .security-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;

        .security-text {
          display: flex;
          flex-direction: column;
          gap: 2.5rem;
        }
      }

      .subscription-plans {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        gap: 5rem;

        .plan {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }

    @media screen and (max-width: $lg) {
      padding: 20px;
    }
  }

  @media screen and (max-width: $lg) {
    padding: 7rem $padding-lg 4rem;
  }
}

main,
.property-insights,
.advantages,
section:has(.security) {
  @media screen and (max-width: $xl) {
    padding-left: $padding-xl;
    padding-right: $padding-xl;
  }

  @media screen and (max-width: $lg) {
    padding: 7rem $padding-lg 4rem;
  }
}

.blob-wrapper {
  position: absolute;
  inset: 0;
  z-index: -1;
}
.blob {
  filter: blur(256px);
  opacity: 0.3;
}

main {
  .blob:nth-child(1) {
    position: absolute;
    top: 0;
    left: 25%;
    right: 75%;
    bottom: 80%;
    width: 800px;
    height: 560px;
    background: $gradient;
    transform: translateX(-50%);

    animation: blob-animation 10s infinite;
  }
  .blob:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 5%;
    right: 95%;
    bottom: 50%;
    width: 446px;
    height: 390px;
    background: $pine-green-primary;
    transform: translateX(-50%);

    animation: blob-animation 6s infinite;
  }
  .blob:nth-child(3) {
    position: absolute;
    top: 110%;
    bottom: -10%;
    left: 20%;
    right: 80%;
    margin: 50% auto;
    inset: 0;
    width: 998px;
    height: 700px;
    background: $gradient;
    transform: translateX(-50%);

    animation: blob-animation 11s infinite;
  }
}

.insights {
  .blob:nth-child(1) {
    position: absolute;
    top: 25%;
    bottom: 75%;
    left: 0;
    right: 90%;
    width: 800px;
    height: 560px;
    background: $red-primary;
    transform: translateX(-50%);

    animation: blob-animation 6s infinite;
  }
  .blob:nth-child(2) {
    position: absolute;
    top: calc(50% - 400px);
    right: 0;
    width: 800px;
    height: 560px;
    background: $yellow-primary;
    transform: translateX(-50%);

    animation: blob-animation 10s infinite;
  }
}

.advantages {
  .blob:nth-child(1) {
    position: absolute;
    top: 0;
    left: 5%;
    right: 95%;
    bottom: 100%;
    width: 997px;
    height: 699px;
    background: $gradient;

    animation: blob-animation 10s infinite;
  }
  .blob:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    width: 790px;
    height: 554px;
    background: $red-primary;
    transform: translateX(-50%);

    animation: blob-animation 6s infinite;
  }
  .blob:nth-child(3) {
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 50%;
    right: 0;
    width: 837px;
    height: 473px;
    background: #007aff;

    animation: blob-animation 11s infinite;
  }
}

section:has(.security) {
  .blob-wrapper {
    height: 100%;
  }
  .blob:nth-child(1) {
    position: absolute;
    top: 40%;
    left: 5%;
    right: 95%;
    bottom: 60%;
    width: 837px;
    height: 473px;
    background: $pine-green-primary;

    animation: blob-animation 10s infinite;
  }
}
