$white: #ffffff;
$black: #0d0d12;

$grey-100: #1b1c1c;
$grey-90: #2d2e2e;
$grey-80: #414545;
$grey-70: #5a605f;
$grey-primary: #78807f;
$grey-50: #838a89;
$grey-40: #a8acac;
$grey-30: #c6c8c7;
$grey-20: #d3d4d4;
$grey-10: #e5e6e6;
$grey-5: #f0f0f0;
$grey-0: #f7f7f7;

$red-primary: #e51045;

$yellow-primary: #ff8000;
$yellow-50: #f2ac66;

$pine-green-primary: #00695e;

$gradient: linear-gradient(90deg, #5091ff 0%, #30bdad 100%);

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$padding-xxl: 9.375rem;
$padding-xl: 4rem;
$padding-lg: 1.5rem;
