/* font styles */

h1 {
  font-weight: 800;
  font-size: 80px;
  line-height: 80px;
  letter-spacing: -0.05em;

  @media (max-width: $lg) {
    font-size: 48px;
    line-height: 48px;
  }
}

h2 {
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;

  @media (max-width: $lg) {
    font-size: 36px;
    line-height: 40px;
  }
}

h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.04em;
}

p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.text-big {
  font-size: 400;
  font-size: 20px;
  line-height: 28px;
}

.nav-link {
  font-size: 1rem;
  font-weight: 600;
  color: $black;
  text-decoration: none;
}

.text-balanced {
  text-wrap: balance;
}

.text-center {
  text-align: center;
}

.text-gradient {
  background: $gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-grey {
  color: $grey-50;
}

/* buttons style */

.btn {
  background-color: $pine-green-primary;
  color: $white;
  border: none;
  padding: 12px 20px;
  border-radius: 10px;
  filter: brightness(1);
  text-decoration: none;
  text-align: center;
}

.btn:hover {
  background-color: $pine-green-primary;
  color: $white;
  filter: brightness(0.8);
}

.ham-btn {
  background-color: $pine-green-primary;
  color: $white;
  border: none;
  padding: 1rem;
  border-radius: 0.5rem;
}

/* badge style */

.badge {
  width: fit-content;
  background: $gradient;
  color: $white;
  padding: 6px 8px;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
}
