header {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  padding: 2.5rem 7.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;

  .menu {
    display: flex;
    align-items: center;
    gap: 2.5rem;
  }

  .options {
    display: flex;
    align-items: center;
    gap: 2.5rem;
  }

  .ham {
    display: none;
    align-items: center;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0px;
    pointer-events: none;
  }

  .mobile-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    background-color: $white;
    padding: $padding-lg;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 3.5rem;

    transition: display 0 ease;

    .controls {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .nav {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2.5rem;
    }
    .options {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2.5rem;

      .btn {
        width: 100%;
      }
    }
  }

  &.scrolled {
    background-color: $white;

    &::before {
      mask-composite: exclude;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgb(255, 255, 255) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      border: 1px solid transparent;
      mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#ffffff 0 0);
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#ffffff 0 0);
      -webkit-mask-composite: destination-out;
    }
  }

  @media screen and (max-width: $xl) {
    padding-left: $padding-xl;
    padding-right: $padding-xl;

    .menu,
    .options {
      gap: 2rem;
    }
  }

  @media screen and (max-width: $lg) {
    padding: $padding-lg;

    .menu,
    .options {
      display: none;
    }

    .ham {
      display: flex;
    }
  }
}
